:root {
  --content-width: 800px;
  --font-family: Assistant, sans-serif;
  --font-monospace: Monaco, Consolas, monospace;
  --font-color: #111111;
  --primary-color: #bf360c;
  --secondary-color: #01579b;
  --input-bg-theme-light: #f7f7f7;
}
@media screen and (max-width: 840px) {
  :root {
    --content-width: calc(100vw - 40px);
  }
}
* {
  accent-color: var(--primary-color);
}
