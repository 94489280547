.text-copy :first-child {
  margin-top: 0;
}
.text-copy {
  padding-bottom: 50px;
}
.text-copy h2 {
  margin-top: 30px;
  margin-bottom: 0;
}
.text-copy ol {
  margin: 0 0 20px 0;
}
.text-copy .token {
  font-weight: bold;
}
.text-copy pre {
  font-family: var(--font-monospace);
  background-color: #111;
  border: 1px solid #000;
  padding: 15px;
  border-radius: 5px;
  font-size: 0.8em;
}
.theme-light .text-copy pre {
  background-color: #eee;
  border: 1px solid #ddd;
}
.text-copy hr {
  color: transparent;
  border: none;
  border-top: 1px solid #444;
  margin: 35px 0;
}
.theme-light .text-copy hr {
  border-color: #ccc;
}
