body,
input,
textarea,
select {
  font-family: var(--font-family);
}
.theme-light,
.theme-light input,
.theme-light textarea,
.theme-light select {
  color: var(--font-color);
}
a {
  text-decoration: none !important;
}
::selection {
  color: white;
  background: #1e65ce;
}
