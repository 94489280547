/* theme-based styles */
.theme-light .MuiInputBase-root {
  background-color: var(--input-bg-theme-light);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
}
.theme-dark .MuiInputBase-root {
  background-color: #191919;
  box-shadow: inset 0 0 6px black;
}
.theme-light .MuiTableContainer-root {
  border: 1px solid #ccc;
}
.theme-dark .MuiTableContainer-root {
  border: 1px solid #444;
}
/* general styles */
.App .MuiTableCell-head {
  font-weight: bold;
  text-transform: uppercase;
}
.App
  .MuiTableBody-root:last-child
  .MuiTableRow-root:last-child
  .MuiTableCell-root {
  border-bottom: none;
}
.MuiAlert-root .Link,
.MuiAlert-root .Link:hover,
.MuiAlert-root .Link:visited {
  color: white;
  text-decoration: underline !important;
}
.theme-dark .MuiAlert-filledError svg {
  fill: #ff7b7b;
}
